import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import { InformTable } from '../../../components/controllers/inform/InformTable'
import { Button, Col, Form, FormControl, FormSelect, InputGroup, Modal, Row, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel, faPlus, faSearch, faSync } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { useAuth, useAxios } from '../../../contexts'
import { Loader } from '../../../components';
import { useAlert, useForm, useNotify, usePermissions, useXLSX } from '../../../hooks'
import { CREAR_INFORME } from '../../../utils';
import moment from 'moment-timezone'

const states = [
  {
    id: 0,
    nombre: "Servicio solicitado"
  },
  {
    id: 1,
    nombre: "Por realizar"
  },
  {
    id: 2,
    nombre: "Realizado"
  },
  {
    id: 3,
    nombre: "En seguimiento"
  },
  {
    id: 4,
    nombre: "Facturado"
  },
  {
    id: 5,
    nombre: "Por aprobar"
  }
];

export const Inform = () => {
  const { user } = useAuth();
  const [showAddDate, setShowAddDate] = useState(false);
  const handleShowDate = () => setShowAddDate(true);
  const handleCloseDate = () => setShowAddDate(false);
  const [showAddImg, setShowAddImg] = useState(false);
  const handleShowImg = () => setShowAddImg(true);
  const handleCloseImg = () => {
    setShowAddImg(false)
    setImg2Show(null);
  };
  const [showEditTechnical, setShowEditTechnical] = useState(false);
  const [informId, setInformId] = useState(null);
  const handleShowEditTechnical = () => setShowEditTechnical(true);
  const handleCloseEditTechnical = () => {
    setInformId(null);
    setShowEditTechnical(false);
  }
  const [loadingTechnical, setLoadingTechnical] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingDate, setLoadingDate] = useState(false);
  const [informs, setInforms] = useState([]);
  const [totals, setTotals] = useState(0);
  const { privateFetch } = useAxios();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [pendingPage, setPendingPage] = useState(false);
  const [filter, setFilter] = useState({ page });
  const [equipment, setEquipment] = useState([]);
  const { serializeFiles, serialize } = useForm();
  const { notify, DelAlert } = useNotify();
  const { Alert } = useAlert();
  const [img2Show, setImg2Show] = useState(null);
  const [loadingImg, setLoadingImg] = useState(false);
  const { validateRoutePermission } = usePermissions();
  const [corregimientos, setCorregimientos] = useState([]);
  const [showCities, setShowCities] = useState([]);
  const [usersS, setUsersS] = useState([]);
  const { generateInform } = useXLSX();
  const [loadingXLSX, setLoadingXLSX] = useState(false);
  const placaRef = useRef();
  useEffect(() => {
    validateRoutePermission(user, CREAR_INFORME)
    document.title = "Informe Tecnico";
    getInforms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  useEffect(() => {
    getInformsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage])

  const getInforms = async () => {
    setLoading(true);
    const [
      { data: equipment },
      { data: cities },
      { data: users },
    ] = await Promise.all([
      privateFetch.get("api/equipment/getTipoEquipos"),
      privateFetch.get("api/location/getCorregimientos"),
      privateFetch.post("/api/users/getUsersFiltered", { orCondition: { rol_id: [2, 3] } }),
      getInformsData()
    ]);
    setUsersS(users?.users ?? []);
    setCorregimientos(cities?.corregimiento ?? [])
    setEquipment(equipment?.tipos);
    setLoading(false);
  }
  const getInformsData = async () => {
    setPendingPage(true);
    const { data } = await privateFetch.post("/api/informs/getInformsFiltered", { ...filter, page, limit: rowsPerPage })
    setInforms(data?.informe);
    setTotals(data?.total);
    setPendingPage(false);
  }
  const onPageChange = (page) => {
    setPage(page);
  }
  const onChangeRowsPerPage = (rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
  }
  const addInform = () => {
    switch (user?.rol_id) {
      case 4:
      case 3:
        handleShowDate();
        break;
      default:
        handleShowDate();
        //navigate(`/inform/add-inform`);
        break;
    }

  };
  const setFilterData = (ev, key) => {
    if (key === 'id') setPage(1);
    if (ev.target?.value === "") {
      return setFilter(prev => {
        const filtroActual = { ...prev };
        delete filtroActual[key];
        return filtroActual;
      });
    } else {
      setFilter(prev => ({
        ...prev,
        ...(!["desde", "hasta", "search", "placa"].includes(key) ? ({ [key]: parseInt(ev.target?.value ?? "0") }) : ({ [key]: ev.target?.value ?? "0" }))
      }))
    }
  }
  const createFixRequest = async (ev) => {
    ev.preventDefault();
    setLoadingDate(true);
    const formData = serializeFiles(ev.target);
    const { data } = await privateFetch.post("/api/informs/saveInform", formData, {
      "Content-type": "multipart/form-data"
    });
    notify({
      ...data,
      callback: () => {
        ev.target.reset();
      },
    });
    getInforms();
    setShowAddDate(false);
    setLoadingDate(false);
  }
  const editInform = (id) => {
    navigate("/inform/edit-inform/" + id)
  }
  const deleteInform = async (id) => {
    await DelAlert({
      text: "Desea eliminar este elemento?",
      onConfirm: async () => {
        const { data } = await privateFetch.delete(
          "/api/informs/deleteInform/" + id
        );
        notify(data);
        getInforms();
      },
      onCancel: () => {
        Alert({
          type: "warning",
          text: "Eliminación cancelada",
        });
      },
    });
  }
  const showImg = async (id) => {
    setLoadingImg(true);
    const inform = informs?.filter(val => val.id === id)?.[0];
    const { data, status } = await privateFetch.get(inform?.foto, {
      responseType: 'blob',
    });
    if (status === 200) {
      const reader = new window.FileReader();
      reader.readAsDataURL(data);
      reader.onload = () => {
        setImg2Show(reader.result);
        handleShowImg();
      }
    }
    setLoadingImg(false);
  }
  const navigatePDF = (idPDF, values = true, user_id = 0) => {
    window.open(`/inform/pdfinform/${idPDF}${values ? '?showValues=1&user_id=' + user_id : ''}`)
  }
  const showCitiesBtn = (ev) => {
    if (ev?.target?.value === "") {
      setShowCities([]);
      return;
    }
    const corrId = parseInt(ev?.target?.value ?? "0");
    const actualCorr = corregimientos?.filter(val => val?.id === corrId)?.[0];
    const actualCityCorreg = corregimientos?.filter(val => val?.city_id === actualCorr?.city_id);
    setShowCities(actualCityCorreg);
  }
  const generateExcel = async (ev) => {
    setLoadingXLSX(true);
    const filterXlsx = { ...filter };
    delete filterXlsx?.page;
    const { data } = await privateFetch.post("/api/informs/getInformsFiltered", { ...filterXlsx })
    const filteredData = data?.informe?.map(val => ({
      fecha_solicitud: moment(val?.hora_inicio_programada).tz("America/Bogota").format("YYYY-MM-DD").toString(),
      ciudad: val?.city?.nombre ?? null,
      codigo_cliente: val?.codigo_cliente,
      nombre_cliente: val?.nombre_cliente,
      direccion: val?.direccion,
      establecimiento: val?.establecimiento,
      telefono: val?.telefono,
      placa: val?.equipo?.placa ?? '',
      falla_reportada: val?.falla_reportada,
      tipo_dano: val?.tipo_refrigeracion_main_tipo_dano?.nombre ?? null,
      tipo_reparacion: null,
      fecha_atencion: val?.fecha,
      averias: val?.main_informe_invs?.filter(val1 => val1?.inv?.tipo === 2)?.length > 0 ? "Sí" : "No",
      estado: [0, 1].includes(val?.estado) ? "Por realizar" : "Realizado",
      tecnico: val?.user?.nombre,
      vendedor: val?.vendor?.nombre
    }))
    generateInform(filter, filteredData);
    setLoadingXLSX(false);
  }
  const changeTechnical = async (id) => {
    setInformId(id);
    handleShowEditTechnical();
  }
  const editTechnical = async (ev) => {
    ev.preventDefault();
    setLoadingTechnical(true);
    const formData = serialize(ev.target);
    const { data } = await privateFetch.put("/api/informs/editTechnical/" + informId, formData);
    notify({
      ...data,
      callback: () => {
        ev.target.reset();
      },
    });
    setLoadingTechnical(false);
    handleCloseEditTechnical();
    getInforms();
  }
  return (
    <>
      <Row className="mb-2">
        <Col sm="auto">
          <Button size="sm" variant="primary" onClick={getInforms}>
            <FontAwesomeIcon icon={faSync} />
          </Button>
        </Col>
        {
          ![2].includes(user?.rol_id) && (
            <Col sm="auto">
              <Button size="sm" variant="primary" onClick={addInform}>
                Crear Reporte &nbsp;
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Col>
          )
        }
        <Col sm="auto">
          <FormSelect size='sm' onChange={e => setFilterData(e, 'estado')} defaultValue={filter?.estado}>
            <option value="">Estado</option>
            {states?.map((val, id) => (
              <option key={id} value={val.id}>
                {val.nombre}
              </option>
            ))}
          </FormSelect>
        </Col>
        <Col sm={3}>
          <FormSelect size='sm' onChange={e => setFilterData(e, 'city_id')} defaultValue={filter?.city_id}>
            <option value="">Ciudad</option>
            {corregimientos?.map((val, id) => (
              <option key={id} value={val.id}>
                {val.nombre} - {val?.city?.departamento?.nombre}
              </option>
            ))}
          </FormSelect>
        </Col>
        <Col sm={3}>
          <FormSelect size='sm' onChange={e => setFilterData(e, 'user_id')} defaultValue={filter?.user_id}>
            <option value="">Usuario</option>
            {usersS?.map((val, id) => (
              <option key={id} value={val.id}>
                {val.nombre} - {val?.rol?.nombre}
              </option>
            ))}
          </FormSelect>
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <label>Desde:</label>
          <FormControl type='date' size='sm' onChange={e => setFilterData(e, 'desde')} defaultValue={filter?.desde} />
        </Col>
        <Col sm={3}>
          <label>Hasta:</label>
          <FormControl type='date' size='sm' onChange={e => setFilterData(e, 'hasta')} defaultValue={filter?.hasta} />
        </Col>
        <Col sm="auto">
          <label>Id</label>
          <FormControl size='sm' type='number' onChange={e => setFilterData(e, 'id')} defaultValue={filter?.id} />
        </Col>
        <Col sm="auto">
          <label>Busqueda</label>
          <FormControl size='sm' type='text' onChange={e => setFilterData(e, 'search')} defaultValue={filter?.search} />
        </Col>
      </Row>
      <Row>
        <Col sm="auto">
          <label>Placa</label>
          <InputGroup className="mb-3" size='sm'>
            <Form.Control
              type='text'
              defaultValue={filter?.placa}
              ref={placaRef}
            />
            <Button variant="outline-secondary" onClick={() => {
              setFilterData({ target: { value: placaRef?.current?.value } }, 'placa')
            }}>
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup>
        </Col>
        <Col sm="auto">
          <br />
          <Button type='button' variant='success' size='sm' onClick={generateExcel} disabled={loadingXLSX} >
            {
              loadingXLSX ? (
                <>
                  <Spinner animation='grow' size='sm' variant='warning' /> &nbsp; Generando excel...
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faFileExcel} />
                </>
              )
            }
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm>
          {
            loading ? (<Loader />) : (
              <>
                <InformTable
                  data={informs}
                  onEdit={editInform}
                  onViewPDF={navigatePDF}
                  onDelete={deleteInform}
                  showImg={showImg}
                  loadingImg={loadingImg}
                  onChangeTechnical={changeTechnical}
                  onChangePage={onPageChange}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                  paginationServer={true}
                  paginationTotalRows={totals}
                  progressPending={pendingPage}
                  progressComponent={<Loader />}
                />
              </>
            )
          }
        </Col>
      </Row>
      <Modal show={showAddDate} onHide={handleCloseDate}>
        <Modal.Header closeButton>
          <Modal.Title>Crear solicitud de reparación</Modal.Title>
        </Modal.Header>
        <Form autoComplete='off' onSubmit={createFixRequest}>
          <Modal.Body>
            <Row>
              <Col sm>
                <label>Tipo de Equipo</label>
                <FormSelect name="tipo_equipo_id" size="sm" required >
                  <option value="">Seleccione</option>
                  {
                    equipment?.map((val) => (
                      <option key={val?.id} value={val?.id}>{val?.nombre}</option>
                    ))
                  }
                </FormSelect>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <label>Regional</label>
                <FormSelect size="sm" onChange={showCitiesBtn}>
                  <option value="">Seleccione</option>
                  {
                    corregimientos?.filter(val => val?.is_city)?.map((val) => (
                      <option key={val?.id} value={val?.id}>{val?.nombre} - {val?.city?.departamento?.nombre}</option>
                    ))
                  }
                </FormSelect>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <label>Ciudad</label>
                <FormSelect name="city_id" size="sm" required >
                  <option value="">Seleccione</option>
                  {
                    showCities?.map((val) => (
                      <option key={val?.id} value={val?.id}>{val?.nombre} - {val?.city?.departamento?.nombre}</option>
                    ))
                  }
                </FormSelect>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <label>Código cliente:</label>
                <FormControl type='number' size="sm" name="codigo_cliente" required />
              </Col>
            </Row>
            <Row>
              <Col sm>
                <label>Nombre cliente:</label>
                <FormControl type='text' size="sm" name="nombre_cliente" required />
              </Col>
            </Row>
            <Row>
              <Col sm>
                <label>Establecimiento:</label>
                <FormControl type='text' size="sm" name="establecimiento" required />
              </Col>
            </Row>
            <Row>
              <Col sm>
                <label>Prioridad</label>
                <FormSelect name="prioridad" size="sm" required >
                  <option value="">Seleccione</option>
                  <option value="1">Baja</option>
                  <option value="2">Media</option>
                  <option value="3">Alta</option>
                </FormSelect>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <label>Teléfono:</label>
                <FormControl type='text' size="sm" name="telefono" required />
              </Col>
            </Row>
            <Row>
              <Col sm>
                <label>Tipo daño:</label>
                <FormControl type='text' size="sm" name="falla_reportada" required />
              </Col>
            </Row>
            <Row>
              <Col sm>
                <label>Dirección:</label>
                <FormControl as={"textarea"} type='text' size="sm" name="direccion" required />
              </Col>
            </Row>
            <Row>
              <Col sm>
                <label>Observaciones:</label>
                <FormControl as={"textarea"} type='text' size="sm" name="observaciones_programadas" required />
              </Col>
            </Row>
            <Row>
              <Col sm>
                <label>Foto:</label>
                <FormControl type='file' size="sm" name="foto" accept='.png,.jpg,.jpeg' />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={handleCloseDate}>
              Cerrar
            </Button>
            <Button size="sm" variant="primary" type='submit' disabled={loadingDate}>
              {
                loadingDate ? (
                  <>
                    <Spinner size='sm' variant="warning" animation="grow" /> Cargando ...
                  </>
                ) : (
                  <>
                    Guardar
                  </>
                )
              }
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal show={showAddImg} onHide={handleCloseImg}>
        <Modal.Header closeButton>
          <Modal.Title>Imagen de solicitud</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm>
              <img src={img2Show} alt="foto" />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={handleCloseImg}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEditTechnical} onHide={handleCloseEditTechnical}>
        <Modal.Header closeButton>
          <Modal.Title>Cambiar técnico</Modal.Title>
        </Modal.Header>
        <Form autoComplete='off' onSubmit={editTechnical}>
          <Modal.Body>
            <Row>
              <Col sm>
                <label>Técnico</label>
                <FormSelect name="user_id" size="sm" required >
                  <option value="">Seleccione</option>
                  {
                    usersS?.filter(val => val?.rol_id === 2)?.map((val) => (
                      <option key={val?.id} value={val?.id}>{val?.nombre}</option>
                    ))
                  }
                </FormSelect>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={handleCloseEditTechnical}>
              Cerrar
            </Button>
            <Button size="sm" variant="primary" type='submit' disabled={loadingTechnical}>
              {
                loadingTechnical ? (
                  <>
                    <Spinner size='sm' variant="warning" animation="grow" /> Cargando ...
                  </>
                ) : (
                  <>
                    Guardar
                  </>
                )
              }
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
