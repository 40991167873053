import { faImage, faSave, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Container, FormControl, Row, Form, Spinner, FormSelect, Modal, Collapse, InputGroup } from 'react-bootstrap';
import { Loader } from '../../Loader/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlert, useForm, useNotify, usePermissions } from '../../../hooks';
import { CREAR_INFORME, EDITAR_INFORME, VALORES_INFORME } from '../../../utils';
import { useAuth, useAxios } from '../../../contexts';
import { Stepper } from '../../General';
import { Checks1 } from './Checks1';
import { Checks2 } from './Checks2';
import { Procedures } from './Procedures';
import { ReplaceParts } from './ReplaceParts';
import { Averias } from './Averias';
import { Sign } from './Sign';
import { DamagePhoto } from './DamagePhoto';

const states = [
    {
        id: 0,
        nombre: "Servicio solicitado"
    },
    {
        id: 1,
        nombre: "Por realizar"
    },
    {
        id: 2,
        nombre: "Realizado"
    },
    {
        id: 3,
        nombre: "En seguimiento"
    },
    {
        id: 4,
        nombre: "Facturado"
    }
];

export const AddInform = ({ edit }) => {
    const { id } = useParams();
    const { user } = useAuth();
    const navigate = useNavigate();
    const { validateRoutePermission, validate } = usePermissions();
    const { privateFetch } = useAxios();
    const [loading, setLoading] = useState(false);
    const [spinnerLoading,] = useState(false);
    const [equipment, setEquiment] = useState([]);
    const [showEquipment, setShowEquipment] = useState([]);
    const [checks1, setChecks1] = useState(false);
    const [touched1, setTouched1] = useState(false);
    const [touched2, setTouched2] = useState(false);
    const [checks2, setChecks2] = useState(false);
    const [touched3, setTouched3] = useState(false);
    const [checks3, setChecks3] = useState(false);
    const [touched4, setTouched4] = useState(false);
    const [checks4, setChecks4] = useState(false);
    const [touched5, setTouched5] = useState(false);
    const [checks5, setChecks5] = useState(false);
    const [touched6, setTouched6] = useState(false);
    const [checks6, setChecks6] = useState(false);
    const [touched7, setTouched7] = useState(false);
    const [checks7, setChecks7] = useState(false);
    const [formData, setFormData] = useState({});
    const [isView, setIsView] = useState(false);
    const [tecnicos, setTecnicos] = useState([]);
    const [refrig, setRefrig] = useState([]);
    const [procedures, setProcedures] = useState([]);
    const [appliedProc, setAppliedProc] = useState([]);
    const [replaceParts, setReplaceParts] = useState([]);
    const [asignados, setAsignados] = useState([]);
    const [appliedParts, setAppliedParts] = useState([]);
    const [averias, setAverias] = useState([]);
    const [appliedAverias, setAppliedAverias] = useState([]);
    const [corregimientos, setCorregimientos] = useState([]);
    const [procValue, setProcValue] = useState(0);
    const [repValue, setRepValue] = useState(0);
    const [totalValor, setTotalValor] = useState(0);
    const [searchString, setSearchString] = useState("");
    const [img2Show, setImg2Show] = useState(null);
    const [showAddImg, setShowAddImg] = useState(false);
    const [loadingImg, setLoadingImg] = useState(false);
    const [damageTypes, setDamageTypes] = useState([]);
    const { Alert } = useAlert();
    const handleShowImg = () => setShowAddImg(true);
    const handleCloseImg = () => {
        setShowAddImg(false)
        setImg2Show(null);
    };
    const { serialize } = useForm();
    const { notify } = useNotify();
    const [showSellerInfo, setShowSellerInfo] = useState(false);
    const placaRef = useRef();
    useEffect(() => {
        if (edit && !id) navigate("/home");
        validateRoutePermission(user, CREAR_INFORME);
        document.title = edit ? "Editar informe" : "Añadir informe";
        getData();
        return () => {
            setFormData({});
        }
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        calculateProcValues();
        calculateRepuestosValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appliedProc, appliedParts]);
    useEffect(() => {
        calculateTotals();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [procValue, repValue]);
    useEffect(() => {
        validateAccess();
        getAsignados();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);
    useEffect(() => {
        setFilteredEquipments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString, equipment, formData]);
    const calculateProcValues = () => {
        let suma = 0;
        for (let i = 0; i < appliedProc.length; i++) {
            const element = appliedProc[i];
            suma += (element?.valor * element?.cantidad) ?? 0;
        }
        setProcValue(suma)
    }
    const selectEquipment = (ev) => {
        if (ev?.target?.value === '') {
            setFormData({
                ...formData,
                equipo_id: null,
                equipo: null
            })
            return;
        }
        const equipoActual = equipment?.filter(val => val?.id === parseInt(ev?.target?.value))?.[0];
        const equipoFD = {
            equipo_id: equipoActual?.id,
            equipo: equipoActual
        }
        setFormData({
            ...formData,
            ...equipoFD
        })
    }
    const selectCity = (ev) => {
        if (ev?.target?.value === '') return;
        const ciudadActual = corregimientos?.filter(val => val?.id === parseInt(ev?.target?.value))?.[0];
        const equipoFD = {
            city: ciudadActual,
        }
        setFormData({
            ...formData,
            ...equipoFD
        })
    }
    const calculateRepuestosValues = () => {
        let suma = 0;
        for (let i = 0; i < appliedParts.length; i++) {
            const element = appliedParts[i];
            suma += ((element?.valor ?? 0) * element?.cantidad) ?? 0;
        }
        setRepValue(suma)
    }
    const calculateTotals = () => {
        let suma = formData?.city?.service_value ?? 0;
        suma += procValue;
        suma += repValue;
        setTotalValor(suma)
    }
    const validateAccess = () => {
        if (!validate(user, EDITAR_INFORME) && formData?.estado === 2) {
            setIsView(true);
        }
    }
    const getAsignados = async () => {
        const { data: asignados } = await privateFetch.post("/api/inventory/getInventoryFiltered", {
            ...(!validate(user, EDITAR_INFORME) ? ({
                estado: true,
                tipo: 1,
                "$main_inv_asignados.user_id$": user?.id,
            }) : ({ estado: true, tipo: 1 }))
        });
        setAsignados(asignados?.inventory);
    }
    const getData = async () => {
        setLoading(true);
        if (edit) {
            const [
                { data: inform },
                { data: users },
                { data: damageType }
            ] = await Promise.all([
                privateFetch.get("/api/informs/getInforms/" + id),
                privateFetch.post("/api/users/getUsersFiltered", {
                    rol_id: 2
                }),
                privateFetch.get("/api/informs/getDamageType")
            ])
            setDamageTypes(damageType.damageType);
            setAppliedProc((inform?.informe?.main_informe_procs ?? [])?.map((val) => {
                return {
                    id: val?.id,
                    proc_id: val?.proc_id,
                    nombre: val?.proc?.nombre,
                    codigo: val?.proc?.codigo,
                    valor: val?.proc?.precio,
                    cantidad: val?.cantidad
                }
            }) ?? []);
            setAppliedParts((inform?.informe?.main_informe_invs ?? [])?.map((val) => {
                return {
                    id: val?.id,
                    inv_id: val?.inv_id,
                    cliente_id: val?.cliente_id ?? null,
                    nombre: val?.inv?.nombre,
                    codigo: val?.inv?.codigo,
                    valor: val?.valor,
                    cantidad: val?.cantidad,
                    type: val?.inv?.tipo,
                    se_cobra: val?.se_cobra,
                }
            }) ?? []);
            setTecnicos(users.users)
            setFormData(inform.informe)
        }
        const [
            { data: equip },
            { data: types },
            { data: proc },
            { data: correg },
            { data: inventory },
            { data: averias }
        ] = await Promise.all([
            privateFetch.get("/api/equipment/getEquipments"),
            privateFetch.get("/api/informs/getRefrigerantTypes"),
            privateFetch.post("/api/procedures/getProceduresFiltered", { estado: true }),
            privateFetch.get("api/location/getCorregimientos"),
            privateFetch.post("/api/inventory/getInventoryFiltered", { estado: true, tipo: 1 }),
            privateFetch.post("/api/inventory/getInventoryFiltered", { estado: true, tipo: 2 })
        ]);
        setCorregimientos(correg.corregimiento)
        setAverias(averias?.inventory);
        setReplaceParts(inventory?.inventory)
        setProcedures(proc.procedures);
        setRefrig(types.refrigerants)
        setEquiment(equip.equipment);
        setLoading(false);
    };
    const submitData = (e) => {
        console.log("Esto no es funcional");
    }
    const saveData = async (ev) => {
        ev.preventDefault();
        setLoading(true);
        const formDataIn = serialize(ev.target);
        const saveCopy = {};
        if (edit) {
            saveCopy.main_informe_procs = formData?.main_informe_procs;
            saveCopy.main_informe_invs = formData?.main_informe_invs;
            saveCopy.refrigerante = formData?.refrigerante;
            saveCopy.user = formData?.user;
            saveCopy.tipo_equipo = formData?.tipo_equipo;
            delete formData?.main_informe_procs;
            delete formData?.main_informe_invs;
            delete formData?.refrigerante;
            delete formData?.user;
            delete formData?.tipo_equipo;
        }
        const allData = {
            ...(edit && ![0, 1].includes(parseInt(formDataIn?.estado ?? "0")) &&
                ({
                    ...formData,
                    procedures: appliedProc,
                    replaceParts: appliedParts,
                    averiasArray: appliedAverias,
                    asignados,
                })),
            ...formDataIn,
        }
        const url = edit ? ("/api/informs/editInform/" + id) : "/api/informs/saveInform";
        const { data } = await privateFetch.post(url, allData);
        notify({
            ...data,
            callback: () => {
                if ([200, 201].includes(data?.code)) navigate("/inform");
                if (edit && data?.reload) getData();
            },
        });
        setLoading(false);
    }
    const showImg = async () => {
        if (!formData?.foto) return;
        setLoadingImg(true);
        try {
            const { data, status } = await privateFetch.get(formData?.foto, {
                responseType: 'blob',
            });
            if (status === 200) {
                const reader = new window.FileReader();
                reader.readAsDataURL(data);
                reader.onload = () => {
                    setImg2Show(reader.result);
                    handleShowImg();
                    setLoadingImg(false);
                }
            }
        } catch (error) {
            Alert({
                type: "warning",
                text: "La imágen está corrupta y no se puede abrir"
            })
            setLoadingImg(false);
        }

    }
    const setFilteredEquipments = () => {
        if (searchString === '' && !formData?.equipo_id) {
            setShowEquipment([]);
            return;
        }
        const filteredEq = equipment?.filter(val => formData?.equipo_id ? val?.id === formData?.equipo_id : val?.placa?.includes(searchString));
        setShowEquipment(filteredEq);
    }
    return (
        <Container fluid className='mt-2'>
            <Card>
                <Card.Header>{edit ? "Editar" : "Añadir"} Reporte</Card.Header>
                {!loading ? (
                    <Form onSubmit={saveData} autoComplete='off'>
                        <Card.Body>
                            {
                                parseInt(formData?.estado ?? "0") === 0 ? (
                                    <>
                                        <Row>
                                            <Col sm>
                                                <label>Disponible desde:</label>
                                                <FormControl type='datetime-local' size="sm" name="hora_inicio_programada" defaultValue={formData?.hora_inicio_programada?.replace("Z", "")} required />
                                            </Col>
                                            <Col sm>
                                                <label>Estado</label>
                                                <FormSelect name="estado" size="sm" required defaultValue={formData?.estado} >
                                                    <option value="">Seleccione</option>
                                                    {
                                                        states?.filter(val => [0, 1].includes(val.id))?.map((val) => (
                                                            <option key={val?.id} value={val?.id}>{val?.nombre}</option>
                                                        ))
                                                    }
                                                </FormSelect>
                                            </Col>
                                            <Col sm>
                                                <label>Asignar a:</label>
                                                <FormSelect name="user_id" size="sm" required defaultValue={formData?.user_id} >
                                                    <option value="">Seleccione</option>
                                                    {
                                                        tecnicos?.map((val) => (
                                                            <option key={val?.id} value={val?.id}>{val?.nombre}</option>
                                                        ))
                                                    }
                                                </FormSelect>
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <>
                                        {
                                            formData?.foto && (
                                                <Row className='mb-3'>
                                                    <Col sm>
                                                        <label>Imagen vendedor</label><br />
                                                        <Button size='sm' onClick={showImg} disabled={loadingImg}>
                                                            {
                                                                loadingImg ? (
                                                                    <>
                                                                        <Spinner size='sm' variant='warning' animation="grow" /> &nbsp;
                                                                        Cargando imagen ...
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <FontAwesomeIcon icon={faImage} />
                                                                    </>
                                                                )
                                                            }
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                        <Row className='mb-3'>
                                            <Col sm>
                                                <Button size='sm' onClick={() => setShowSellerInfo(!showSellerInfo)} aria-controls='collapse-info' aria-expanded={false}>
                                                    Mostrar info de vendedor
                                                </Button>
                                            </Col>
                                            <Collapse in={showSellerInfo}>
                                                <div id="collapse-info">
                                                    <Row className='my-2'>
                                                        <Col sm={3}>
                                                            <label>Nombre cliente:</label>
                                                            <FormControl type='text' size="sm" defaultValue={formData?.nombre_cliente} readOnly />
                                                        </Col>
                                                        <Col sm={3}>
                                                            <label>Establecimiento:</label>
                                                            <FormControl type='text' size="sm" defaultValue={formData?.establecimiento} readOnly />
                                                        </Col>
                                                        <Col sm={3}>
                                                            <label>Teléfono:</label>
                                                            <FormControl type='text' size="sm" defaultValue={formData?.telefono} readOnly />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm>
                                                            <label>Dirección</label>
                                                            <FormControl readOnly as={"textarea"} size="sm" type="text" defaultValue={formData?.direccion} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm>
                                                            <label>Observaciones vendedor</label>
                                                            <FormControl readOnly as={"textarea"} size="sm" type="text" defaultValue={formData?.observaciones_programadas} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Collapse>
                                        </Row>
                                        <Row>
                                            <Col sm>
                                                <label>Placa a buscar</label>
                                                <InputGroup size='sm' className="mb-3">
                                                    <Form.Control
                                                        size='sm'
                                                        ref={placaRef}
                                                        placeholder='Placa...'
                                                    />
                                                    <Button variant="outline-secondary" onClick={() => {
                                                        setSearchString(placaRef?.current?.value);
                                                    }}>
                                                        <FontAwesomeIcon icon={faSearch} />
                                                    </Button>
                                                </InputGroup>
                                            </Col>
                                            <Col sm>
                                                <label>Placa Equipo</label>
                                                <FormSelect defaultValue={edit !== false ? formData?.equipo_id : ''} size="sm" disabled={isView} name="equipo_id" required onChange={selectEquipment} >
                                                    {
                                                        showEquipment?.length === 0 ? (
                                                            <>
                                                                <option value="">No hay congeladores filtrados</option>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <option value="">Seleccione</option>
                                                            </>
                                                        )

                                                    }
                                                    {showEquipment.map((val, id) => (
                                                        <option key={id} value={val.id}>
                                                            {val?.placa}
                                                        </option>
                                                    ))}
                                                </FormSelect>
                                            </Col>
                                            <Col sm>
                                                <label>Ciudad</label>
                                                <FormSelect disabled={isView} name="city_id" size="sm" required defaultValue={formData?.city_id} onChange={selectCity} >
                                                    <option value="">Seleccione</option>
                                                    {
                                                        corregimientos?.map((val) => (
                                                            <option key={val?.id} value={val?.id}>{val?.nombre} - {val?.city?.departamento?.nombre}</option>
                                                        ))
                                                    }
                                                </FormSelect>
                                            </Col>
                                            <Col sm>
                                                <label>Código cliente</label>
                                                <FormControl disabled={isView} size="sm" type="number" name="codigo_cliente" defaultValue={formData?.codigo_cliente} />
                                            </Col>
                                            <Col sm>
                                                <label>Tipo Operación</label>
                                                <FormSelect disabled={isView} size="sm" name="tipo_operacion" required defaultValue={formData?.tipo_operacion} >
                                                    <option value="">Seleccione</option>
                                                    {[
                                                        { id: 1, nombre: "Preventivo" },
                                                        { id: 2, nombre: "Correctivo" },
                                                        { id: 3, nombre: "Alistamiento" },
                                                        { id: 4, nombre: "Baja" }
                                                    ].map((val, id) => (
                                                        <option key={id} value={val.id}>
                                                            {val?.nombre}
                                                        </option>
                                                    ))}
                                                </FormSelect>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm>
                                                <label>Tipo Reparación</label>
                                                <FormSelect disabled={isView} size="sm" name="tipo_reparacion" required defaultValue={formData?.tipo_reparacion} >
                                                    <option value="">Seleccione</option>
                                                    {[{ id: 1, nombre: "Mayor" }, { id: 2, nombre: "Menor" }].map((val, id) => (
                                                        <option key={id} value={val.id}>
                                                            {val?.nombre}
                                                        </option>
                                                    ))}
                                                </FormSelect>
                                            </Col>
                                            <Col sm>
                                                <label>Hora final</label>
                                                <FormControl size="sm" type="time" defaultValue={formData?.hora_final} required disabled={!loading || isView} />
                                            </Col>
                                            <Col sm>
                                                <label>Recibe</label>
                                                <FormControl disabled={isView} size="sm" type="text" name="persona_recibe" defaultValue={formData?.persona_recibe} required />
                                            </Col>
                                            <Col sm>
                                                <label>Tipo Daño</label>
                                                <FormSelect disabled={isView} size="sm" name="tipo_reparacion" required defaultValue={formData?.tipo_refrigeracion} >
                                                    <option value="">Seleccione</option>
                                                    {
                                                        damageTypes.map((val, id) => (
                                                            <option key={id} value={val.id}>
                                                                {val?.nombre}
                                                            </option>
                                                        ))

                                                    }
                                                </FormSelect>
                                            </Col>
                                            <Col sm>
                                                <label>Estado</label>
                                                <FormSelect disabled={isView} size="sm" name="estado" required defaultValue={formData?.estado}>
                                                    <option value="">Seleccione</option>
                                                    {[{ id: 2, nombre: "Realizado" }, { id: 3, nombre: "En seguimiento" }].map((val, id) => (
                                                        <option key={id} value={val.id}>
                                                            {val?.nombre}
                                                        </option>
                                                    ))}
                                                </FormSelect>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={3}>
                                                <label>Tipo equipo</label>
                                                <FormControl readOnly size="sm" defaultValue={formData?.tipo_equipo?.nombre} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <label>Falla Reportada</label>
                                                <FormControl disabled={isView} as={"textarea"} size="sm" type="text" name="falla_reportada" defaultValue={formData?.falla_reportada} required />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <label>Observaciones</label>
                                                <FormControl disabled={isView} as={"textarea"} size="sm" type="text" name="observaciones" defaultValue={formData?.observaciones} required />
                                            </Col>
                                        </Row>
                                        {
                                            showEquipment?.length > 0 && (
                                                <>
                                                    <Row className='mt-3'>
                                                        <Col sm>
                                                            <Stepper isInline stepperContent={[
                                                                {
                                                                    label: "Chequeos 1",
                                                                    content: <Checks1 inform={formData} checks={checks1} setChecks={setChecks1} setTouched1={setTouched1} setFormData={setFormData} />,
                                                                    isComplete: checks1 && touched1,
                                                                    isError: !checks1 && touched1,
                                                                },
                                                                {
                                                                    label: "Chequeos 2",
                                                                    content: <Checks2 inform={formData} refrig={refrig} checks={checks2} setChecks={setChecks2} setTouched2={setTouched2} setFormData={setFormData} />,
                                                                    isComplete: checks2 && touched2,
                                                                    isError: !checks2 && touched2,
                                                                },
                                                                {
                                                                    label: "Procedimientos",
                                                                    content: <Procedures edit={edit} procedures={procedures} appliedProc={appliedProc} setProcedures={setAppliedProc} inform={formData} setChecks={setChecks3} setTouched={setTouched3} />,
                                                                    isComplete: checks3 && touched3,
                                                                    isError: !checks3 && touched3,
                                                                },
                                                                {
                                                                    label: "Repuestos",
                                                                    content: <ReplaceParts setAsignados={setAsignados} edit={edit} asignados={asignados} replaceParts={replaceParts} appliedParts={appliedParts} setParts={setAppliedParts} inform={formData} setChecks={setChecks4} setTouched={setTouched4} />,
                                                                    isComplete: checks4 && touched4,
                                                                    isError: !checks4 && touched4,
                                                                },
                                                                ...(formData?.equipo?.cliente?.informe_averias ? [{
                                                                    label: "Averias",
                                                                    content: <Averias edit={edit} averias={averias} appliedAverias={appliedAverias} setAverias={setAppliedAverias} inform={formData} setChecks={setChecks5} setTouched={setTouched5} />,
                                                                    isComplete: checks5 && touched5,
                                                                    isError: !checks5 && touched5,
                                                                }] : []
                                                                ),
                                                                {
                                                                    label: "Foto placa",
                                                                    content: <DamagePhoto id={id} setFormData={setFormData} inform={formData} setChecks={setChecks7} setTouched={setTouched7} />,
                                                                    isComplete: checks7 && touched7,
                                                                    isError: !checks7 && touched7,
                                                                },
                                                                {
                                                                    label: "Firma",
                                                                    content: <Sign inform={formData} setFormData={setFormData} setChecks={setChecks6} setTouched={setTouched6} />,
                                                                    isComplete: checks6 && touched6,
                                                                    isError: !checks6 && touched6,
                                                                },
                                                            ]} submitStepper={submitData} />
                                                        </Col>
                                                    </Row>
                                                </>
                                            )
                                        }
                                        {
                                            validate(user, VALORES_INFORME) && (
                                                <Row className='mt-2'>
                                                    <Col sm>
                                                        Totales: <br /> {totalValor} COP
                                                    </Col>
                                                    <Col sm>
                                                        Mano de obra:<br /> {procValue} COP
                                                    </Col>
                                                    <Col sm>
                                                        Materiales:<br /> {repValue} COP
                                                    </Col>
                                                    <Col sm>
                                                        Transporte:<br /> {formData?.city?.service_value ?? 0} COP
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                    </>
                                )
                            }

                        </Card.Body>
                        <Card.Footer>
                            {
                                !isView && formData?.estado !== 4 ? (
                                    <Button type='submit' size='sm' variant='primary' disabled={spinnerLoading || ((!checks1 || !checks2 || !checks3 || !checks4 || (formData?.equipo?.cliente?.informe_averias ? !checks5 : true) || !checks6))}>
                                        {
                                            spinnerLoading ? (
                                                <>
                                                    <Spinner animation="grow" variant="warning" />
                                                </>
                                            ) : (
                                                <>
                                                    <FontAwesomeIcon icon={faSave} /> &nbsp;
                                                    Guardar
                                                </>
                                            )
                                        }
                                    </Button>
                                ) : (
                                    <>
                                        No autorizado a editar el informe
                                    </>
                                )
                            }
                        </Card.Footer>
                    </Form>
                ) : (
                    <Loader />
                )
                }
            </Card >
            <Modal show={showAddImg} onHide={handleCloseImg}>
                <Modal.Header closeButton>
                    <Modal.Title>Imagen de solicitud</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm>
                            <img src={img2Show} alt="foto" />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="secondary" onClick={handleCloseImg}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container >
    )
}
